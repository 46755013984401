import React from 'react';
import PageHeaderHome from "../components/PageHeaderHome";
import HomePageBanner2 from "../components/HomePageBanner2";
import ApplicationAreas from "../components/ApplicationAreas";
import HomePageBanner1 from "../components/HomePageBanner1";
import HomePageBanner3 from "../components/HomePageBanner3";
const Home = () => {


  return (
    <div>
      <PageHeaderHome></PageHeaderHome>
      <HomePageBanner1></HomePageBanner1>
      <HomePageBanner2></HomePageBanner2>
      <ApplicationAreas></ApplicationAreas>
      <HomePageBanner3></HomePageBanner3>
    </div> 
  );
};

export default Home;
