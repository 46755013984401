import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCardIndustry from "../appareas/AppCardIndustry"
import AppCardHealthcare from "../appareas/AppCardHealthcare"
import AppCardEcommerce from "../appareas/AppCardEcommerce"
import AppCardSecurity from "../appareas/AppCardSecurity"

const ApplicationAreas = ({title,description,link}) =>  {
  return (
    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>

      <div className="flex p-8 gap-8">
            <AppCardEcommerce/>         
            <AppCardIndustry/>
             <AppCardSecurity/>
            <AppCardHealthcare/>           
      </div>

    </div>
    
  );
}
export default ApplicationAreas;
