import React from 'react';
import SecurityApps from "./SecurityApps";
import PageHeader from "../components/PageHeader"

const Security = () => {
  return (
    <div>
        <PageHeader/>
        <SecurityApps/>
    </div>          
  );
};

export default Security;
