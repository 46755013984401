import React from "react";
import BackgroudImage from "../assets/images/bg/3.jpg";
import { useTranslation } from 'react-i18next';
import {ArrowRightOutlined} from '@ant-design/icons';


export default function HealthcareBanner() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-16 lg:py-16 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12 flex justify-between">
              <div className="">
                    <h4 className="text-white lg:text-4xl text-3xl font-medium mb-7 flex">
                      <i>{t("providing_brilliant")} <ArrowRightOutlined/> <b className="text-[#8080ff]">{t("healthcare")}</b> {" "}</i>
                      <div className="ml-3 border"></div> 
                      <div className="ml-3 text-orange-400 text-2xl">
                        <p>{t("diagnostics")}</p>
                        <p>{t("public_health")}</p>
                        <p>{t("gene_expression")}</p>
                      </div>
                    </h4>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
