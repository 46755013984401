import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import rootcauseanalysis from "../assets/images/applications/rootcauseanalysis.png";

const PredictiveMaintenance = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            Predictive Maintenance
          </h3>
        </div>

        <div className="grid gap-6 grid-cols-3">
          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">1</div>
              <div>What is Predictive Maintenance?</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Predictive maintenance is a proactive maintenance strategy that
              uses data analysis and machine learning to predict when equipment
              will likely fail, allowing for timely maintenance before a
              breakdown occurs.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">2</div>
              <div>Why Predictive Maintenance?</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Predictive maintenance helps reduce unplanned downtime, extend
              equipment life, and optimize maintenance costs by addressing
              issues before they lead to failure.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">3</div>
              <div>Shortcomings of Traditional Predictive Maintenance</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              The shortcomings of traditional predictive maintenance include
              high initial setup costs, reliance on large amounts of accurate
              data, difficulty in integrating with existing systems, and the
              need for specialized expertise to interpret data and maintain
              predictive models.
            </div>
          </div>

          <div className="text-xl  border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4</div>
              <div>AI Based Predictive Maintenance</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              AI-based predictive maintenance leverages advanced machine
              learning algorithms and AI techniques to analyze large datasets
              from equipment sensors, predict failures with higher accuracy, and
              continuously improve prediction models over time, enabling more
              efficient and precise maintenance planning.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.1</div>
              <div>
                AI Based Predictive Maintenance / <i className="text-gray-500">Data Collection</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Sensors, IoT devices, and other data sources collect real-time
              data from equipment, including vibration, temperature, pressure,
              and operational performance.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.2</div>
              <div>
                AI Based Predictive Maintenance /{" "}
                <i className="text-gray-500">Data Processing and Storage</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Collected data is cleaned, organized, and stored in databases or
              cloud systems for further analysis.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.3</div>
              <div>
                AI Based Predictive Maintenance /{" "}
                <i className="text-gray-500">Feature Engineering</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Relevant features and indicators, such as anomaly detection or
              trend changes, are extracted from raw data to make it useful for
              predictive models.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.4</div>
              <div>
                AI Based Predictive Maintenance /{" "}
                <i className="text-gray-500">Machine Learning Models</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              AI and machine learning algorithms (e.g., regression models,
              neural networks, or decision trees) are trained using historical
              data to detect patterns and predict when a failure might occur.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.5</div>
              <div>
                AI Based Predictive Maintenance /{" "}
                <i className="text-gray-500">Predictive Analytics</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              The models continuously analyze real-time data, generating
              predictions on the likelihood of equipment failure, degradation
              trends, or required maintenance.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.6</div>
              <div>
                AI Based Predictive Maintenance /{" "}
                <i className="text-gray-500">
                  Automated Maintenance Scheduling
                </i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Based on predictions, the system automatically schedules
              maintenance activities before failures occur, optimizing
              maintenance cycles and reducing downtime.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.7</div>
              <div>
                AI Based Predictive Maintenance / <i className="text-gray-500">Feedback Loop</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              AI models are continuously updated and refined with new data to
              improve accuracy over time, making the system smarter and more
              reliable as it learns from past performance.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.8</div>
              <div>
                AI Based Predictive Maintenance /{" "}
                <i className="text-gray-500">User Interface and Alerts</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Insights, predictions, and alerts are presented to users through
              dashboards or mobile applications, enabling maintenance teams to
              make informed decisions.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PredictiveMaintenance;
