import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import IndustryBanner from "./IndustryBanner";
import { useTranslation } from 'react-i18next';

const IndustryApps = () =>  {
  const { t } = useTranslation();
  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>

      <div> <IndustryBanner></IndustryBanner> </div>
      
      <div className="flex p-8 gap-8">
            <AppCard title={t("predictive_maintenance")} description={t("predictive_maintenance_description")} link="PredictiveMaintenance"></AppCard>
            <AppCard title={t("root_cause_analysis")} description={t("root_cause_analysis_description")} link="RootCauseAnalysis"></AppCard>
            <AppCard title={t("process_mining")} description={t("process_mining_description")} link="ProcessMining"></AppCard>
      </div>
      <div className="flex p-8 gap-8">
            <AppCard title={t("alarm_analysis")} description={t("alarm_analysis_description")} link="AlarmAnalysis"></AppCard>
            <AppCard title={t("time_series_forecasting")} description={t("time_series_forecasting_description")} link="TimeSeriesForecasting"></AppCard>
      </div>

    </div>
    
  );
}
export default IndustryApps;
