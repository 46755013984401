import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeaderSolutions from "../components/PageHeaderSolutions";

const AlarmAnalysis = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
         
        <div className='items-center text-center'>
              <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">Alarm Analysis</h3>
        </div>

        Scenario: A Telecommunications Network Outage
In a large telecommunications company, network outages are becoming frequent, but the cause is unclear. By using pattern discovery, analysts find that these outages are correlated with high traffic volumes at certain times of the day and only occur when specific types of data packets are being transmitted. Further analysis reveals that a certain router's firmware has a bug that causes it to fail under these exact conditions. The firmware is updated, resolving the issue.


      </section>
    </>
  );
};

export default AlarmAnalysis;
