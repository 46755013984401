import React from 'react';
import IndustryApps from "./IndustryApps";
import PageHeader from "../components/PageHeader"

const Industry = () => {
  return (
    <div>
        <PageHeader/>
        <IndustryApps/>
    </div>          
  );
};

export default Industry;
