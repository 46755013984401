import React from "react";
import BackgroudImage from "../assets/images/bg/hero-bg1.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {CaretRightOutlined} from "@ant-design/icons"


export default function HomePageBanner1() {

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleOpenModal = () => {
    setIsVideoModalOpen(true);
    setIsVideoPlaying(true);
  };

  const handleCloseModal = () => {
    setIsVideoModalOpen(false);
    setIsVideoPlaying(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-16 lg:py-16 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
          <div className="grid grid-cols-2 mt-12 items-center">
            <div>
              <div className="text-slate-900 mb-10 max-w-2xl text-6xl">
                {t("homepage_banner1_text1")}...
              </div>

              <div className="text-3xl max-w-2xl ml-10">
                <b className="text-[#0000ff] font-orbitron font-normal">
                  datanom
                </b>
                <i>, {t("homepage_banner1_text2")}</i>
              </div>
            </div>

            <div className="grid grid-cols-2 ml-36">

              <button
                onClick={handleOpenModal}
                className="flex items-center"
              >
                  <i className="text-4xl text-sky-800">{t("demo_video")}</i>
                  <span>
                    <span className="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-sky-400 opacity-65"></span>
                    <span className="text-4xl text-sky-800"><CaretRightOutlined /></span>
                  </span>
              </button>

            </div>
          </div>
        </section>

        <div className=" ">
          {isVideoModalOpen && (
            <div className=" flex justify-center items-center">
              <div
                className={`modal ${
                  isVideoModalOpen ? "block" : "hidden"
                } fixed pt-[5%] top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 z-100 overflow-y-auto`}
              >
                <div className="modal-content w-full md:w-[620px] mx-auto rounded py-4 px-6">
                  <div className="modal-body">
                    {isVideoPlaying && (
                      <div className="relative">
                        <button
                          className="absolute top-[-35px] bg-white rounded-2xl right-[-30px] md:right-[-80px] m-4 text-gray-600 hover:text-gray-800"
                          onClick={handleCloseModal}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="black"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                        <iframe
                          // width="560"
                          // height="315"
                          className="w-full md:w-[620px] h-[315px] "
                          src="https://www.youtube.com/embed/AD303TuXl54"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
