
import { useTranslation } from 'react-i18next';
import PageHeaderAbout from "../components/PageHeaderAbout";
import BackgroudImage from "../assets/images/bg/3.jpg";

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderAbout></PageHeaderAbout>
      <div>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-96 lg:py-96 w-full table relative bg-center bg-cover pl-5"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">

            <p className="text-gray-300 max-w-2xl text-2xl leading-10">
            {t("about_text_1")}
            </p>
            <p className="ml-5 text-gray-400 max-w-2xl text-2xl leading-10">
            {t("about_text_2")}
            </p>

          </div>
        </section>
      </div>
    </>
  );
};

export default About;
