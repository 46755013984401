import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import AppCard from "./AppCard"
import HealthcareBanner from "./HealthcareBanner";
import { useTranslation } from 'react-i18next';



const HealthcareApps = () =>  {
  const { t } = useTranslation();
  return (

    <div className="flex-col" style={{ backgroundImage: `url(${BackgroudImage})` }}>
      
      <div> <HealthcareBanner></HealthcareBanner> </div>


      <div className="flex p-8 gap-8">
            <AppCard title={t("diagnostics")} description={t("diagnostics_description")} link="Diagnostics"></AppCard>
            <AppCard title={t("public_health")} description={t("public_health_description")} link="PublicHealth"></AppCard>
            <AppCard title={t("gene_expression")} description={t("gene_expression_description")} link="GeneExpression"></AppCard>
      </div>

    </div>
    
  );
}
export default HealthcareApps;
