import React from 'react';
import EcommerceApps from "./EcommerceApps";
import PageHeader from "../components/PageHeader"

const Ecommerce = () => {
  return (
    <div>
        <PageHeader/>
        <EcommerceApps/>
    </div>          
  );
};

export default Ecommerce;
