import React from 'react';
import { Link } from "react-router-dom";
import {LinkedinOutlined, InstagramOutlined, XOutlined} from "@ant-design/icons"
import ScrollToTop from "react-scroll-to-top";
import { ReactComponent as MySVG } from "./top.svg";


export default function PageFooter() {

    return (
        <>
            {/*  Start Footer  */}
            <footer className="p-10 bg-blue-900 dark:bg-gray-900">

                <div>

                    <div className="items-center flex justify-between w-full">


                        <div><b className="text-white font-orbitron font-normal">datanom</b></div>

                        <div className='text-white'> datanom.io © {(new Date().getFullYear())}{" "}</div>

                        <div>
                            <ul className="items-center flex">

                                <li className='pr-4'>
                                    
                                    <Link to="https://linkedin.com/">
                                        <button class="w-8 h-8 rounded-lg bg-white ml-2">
                                        <LinkedinOutlined/>
                                        </button>
                                    </Link>

                                    <Link to="https://instagram.com/">
                                        <button class="w-8 h-8 rounded-lg bg-white ml-2">
                                        <InstagramOutlined/>
                                        </button>
                                    </Link>

                                    <Link to="https://x.com/">
                                        <button class="w-8 h-8 rounded-lg bg-white ml-2">
                                        <XOutlined />
                                        </button>
                                    </Link>
                                </li>



                            </ul>
                        </div>

                        <div></div>

                    </div>

                </div>
                <ScrollToTop smooth="True" component={<MySVG/>}/>
            </footer>
            {/* <!-- End Footer --> */}


        </>
    );
}
