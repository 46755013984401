import React from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';
import { ReactComponent as USFlag } from '../flags/us.svg'; // Import flag SVGs
// import { ReactComponent as FRFlag } from '../flags/fr.svg';
// import { ReactComponent as DEFlag } from '../flags/de.svg';
// import { ReactComponent as ESFlag } from '../flags/es.svg';
import { ReactComponent as TRFlag } from '../flags/tr.svg';



/**
 * Index Component
 */
export default function PageHeaderAbout() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang)
    setIsOpen(false);
  };

  return (
    <>
      <nav className="text-base bg-gray-50 text-gray-900 p-4 fixed w-full top-0 z-10 flex justify-between items-center">

        <div className="text-2xl font-orbitron text-center">
          <a href='/'> <b className="text-[#0000ff] font-orbitron font-normal">datanom</b> </a>
        </div>


        <div className="flex text-center">
          <a href="/home" className="p-2 hover:text-orange-700 ml-10 border-2 rounded-xl border-gray-50 ">{t('navbar.home')}</a>
          <a href="/about" className="p-2 text-orange-700 ml-10 border-2 rounded-xl border-orange-700">{t('navbar.about')}</a>
          <a href="/solutions" className="p-2 hover:text-orange-700 ml-10 border-2 rounded-xl border-gray-50">{t('navbar.solutions')}</a>
          <a href="/contact" className="p-2 hover:text-orange-700  ml-10 border-2 rounded-xl border-gray-50">{t('navbar.contact')}</a>
        </div>
        <button><a href="https://datanom.us" className="hover:text-white-900 hover:bg-gray-200 border-2 p-2 border-gray-700 text-gray-700 rounded-xl">{t('navbar.login')}</a></button>
        <div className="relative">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="bg-gray-700 text-white text-sm px-4 py-2 rounded-md"
          >
            {t('navbar.language')} <span className="ml-2">▼</span>
          </button>
          {isOpen && (
            <div className="absolute right-0 mt-2 bg-white text-gray-900 rounded-md shadow-lg">
              <button onClick={() => changeLanguage('en')} className="flex items-center px-4 py-2 hover:bg-gray-200 w-full text-left">
                <USFlag className="w-6 h-6 mr-2" /> English
              </button>
              {/* <button onClick={() => changeLanguage('fr')} className="flex items-center px-4 py-2 hover:bg-gray-200 w-full text-left">
                <FRFlag className="w-6 h-6 mr-2" /> Français
              </button>
              <button onClick={() => changeLanguage('de')} className="flex items-center px-4 py-2 hover:bg-gray-200 w-full text-left">
                <DEFlag className="w-6 h-6 mr-2" /> Deutsch
              </button>
              <button onClick={() => changeLanguage('es')} className="flex items-center px-4 py-2 hover:bg-gray-200 w-full text-left">
                <ESFlag className="w-6 h-6 mr-2" /> Español
              </button> */}
              <button onClick={() => changeLanguage('tr')} className="flex items-center px-4 py-2 hover:bg-gray-200 w-full text-left">
                <TRFlag className="w-6 h-6 mr-2" /> Türkçe
              </button>
            </div>
          )}
        </div>
      </nav>
    </>
  );
}
