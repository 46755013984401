import { Link } from "react-router-dom";
import EcommerceImage from "../assets/images/applications/e-commerce.png";
import {CaretRightFilled} from "@ant-design/icons"
import { useTranslation } from 'react-i18next';
const AppCardEcommerce = () =>  {
    const { t } = useTranslation();
  return (

      <div className="text-3xl w-[90%] border-2 border-gray-200 rounded-3xl shadow-2xl h-full bg-slate-50 opacity-80 max-w-[50%]   hover:bg-slate-100 hover:scale-[1.02] duration-300">
        <a href="/solutions/ecommerce">
        <div className="pb-2 font-bold text-gray-900 items-center m-1 opacity-90 flex">
        <img src={EcommerceImage} className='w-16 h-16 p-3' alt=""/>
        {t("e_commerce_uppercase")}
        </div>
        <div className="border-2 border-gray-100"></div>
        <div className="text-2xl p-3 m-1 opacity-90 text-justify">
          {t("e_commerce_description")}
          <div className="text-[#0000ff] pt-8">
            <Link to="/solutions/ecommerce" className="flex">
            <CaretRightFilled/>{t("Read_More")}...
            </Link>
          </div>
        </div>        
        </a>

      </div>
      
  );
}
export default AppCardEcommerce;
