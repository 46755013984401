import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageFooter from "./components/PageFooter";
import Home from './pages/Home';
import About from './pages/About';
import Solutions from './pages/Solutions';
import Contact from './pages/Contact';
import Ecommerce from './appareas/Ecommerce';
import Industry from './appareas/Industry';
import Security from './appareas/Security';
import Healthcare from './appareas/Healthcare';

import RecommenderSystems from './applications/RecommenderSystems'
import MarketBasketAnalysis from './applications/MarketBasketAnalysis'
import StoreOptimization from './applications/StoreOptimization'
import MarketingAutomation from './applications/MarketingAutomation'
import LoyaltyProgramDevelopment from './applications/LoyaltyProgramDevelopment'
import CampaignOptimisation from './applications/CampaignOptimisation'
import PredictiveMaintenance from './applications/PredictiveMaintenance'
import RootCauseAnalysis from './applications/RootCauseAnalysis'
import ProcessMining from './applications/ProcessMining'
import AlarmAnalysis from './applications/AlarmAnalysis'
import TimeSeriesForecasting from './applications/TimeSeriesForecasting'
import CrimeInvestigation from './applications/CrimeInvestigation'
import FraudDetection from './applications/FraudDetection'
import IntrusionDetection from './applications/IntrusionDetection'
import LieDetection from './applications/LieDetection'
import Diagnostics from './applications/Diagnostics'
import PublicHealth from './applications/PublicHealth'
import GeneExpression from './applications/GeneExpression'




const App = () => {

  return (
    <Router>

      {/* <PageHeader/> */}
     
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/solutions" element={<Solutions/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />

        <Route path="/solutions/ecommerce" element={<Ecommerce/>} />
        <Route path="/solutions/industry" element={<Industry/>} />
        <Route path="/solutions/security" element={<Security/>} />
        <Route path="/solutions/healthcare" element={<Healthcare/>} />

        <Route path="/solutions/RecommenderSystems" element={<RecommenderSystems/>} />
        <Route path="/solutions/ecommerce/RecommenderSystems" element={<RecommenderSystems/>} />
        <Route path="/solutions/MarketBasketAnalysis" element={<MarketBasketAnalysis/>} />
        <Route path="/solutions/ecommerce/MarketBasketAnalysis" element={<MarketBasketAnalysis/>} />
        <Route path="/solutions/StoreOptimization" element={<StoreOptimization/>} />
        <Route path="/solutions/ecommerce/StoreOptimization" element={<StoreOptimization/>} />
        <Route path="/solutions/MarketingAutomation" element={<MarketingAutomation/>} />
        <Route path="/solutions/ecommerce/MarketingAutomation" element={<MarketingAutomation/>} />
        <Route path="/solutions/LoyaltyProgramDevelopment" element={<LoyaltyProgramDevelopment/>} />
        <Route path="/solutions/ecommerce/LoyaltyProgramDevelopment" element={<LoyaltyProgramDevelopment/>} />
        <Route path="/solutions/CampaignOptimisation" element={<CampaignOptimisation/>} />
        <Route path="/solutions/ecommerce/CampaignOptimisation" element={<CampaignOptimisation/>} />

        <Route path="/solutions/PredictiveMaintenance" element={<PredictiveMaintenance/>} />
        <Route path="/solutions/industry/PredictiveMaintenance" element={<PredictiveMaintenance/>} />
        <Route path="/solutions/RootCauseAnalysis" element={<RootCauseAnalysis/>} />
        <Route path="/solutions/industry/RootCauseAnalysis" element={<RootCauseAnalysis/>} />
        <Route path="/solutions/ProcessMining" element={<ProcessMining/>} />
        <Route path="/solutions/industry/ProcessMining" element={<ProcessMining/>} />
        <Route path="/solutions/AlarmAnalysis" element={<AlarmAnalysis/>} />
        <Route path="/solutions/industry/AlarmAnalysis" element={<AlarmAnalysis/>} />
        <Route path="/solutions/TimeSeriesForecasting" element={<TimeSeriesForecasting/>} />
        <Route path="/solutions/industry/TimeSeriesForecasting" element={<TimeSeriesForecasting/>} />        

        <Route path="/solutions/CrimeInvestigation" element={<CrimeInvestigation/>} />
        <Route path="/solutions/security/CrimeInvestigation" element={<CrimeInvestigation/>} /> 
        <Route path="/solutions/FraudDetection" element={<FraudDetection/>} />
        <Route path="/solutions/security/FraudDetection" element={<FraudDetection/>} /> 
        <Route path="/solutions/IntrusionDetection" element={<IntrusionDetection/>} />
        <Route path="/solutions/security/IntrusionDetection" element={<IntrusionDetection/>} /> 
        <Route path="/solutions/LieDetection" element={<LieDetection/>} />
        <Route path="/solutions/security/LieDetection" element={<LieDetection/>} />

        <Route path="/solutions/Diagnostics" element={<Diagnostics/>} />
        <Route path="/solutions/healthcare/Diagnostics" element={<Diagnostics/>} />
        <Route path="/solutions/PublicHealth" element={<PublicHealth/>} />
        <Route path="/solutions/healthcare/PublicHealth" element={<PublicHealth/>} />
        <Route path="/solutions/GeneExpression" element={<GeneExpression/>} />
        <Route path="/solutions/healthcare/GeneExpression" element={<GeneExpression/>} />
      </Routes>

      <PageFooter/>
    </Router>
  );
};

export default App;
