import React from "react";
import { useTranslation } from "react-i18next";
import BackgroudImage from "../assets/images/bg/choose-us-bg3.jpg";
import PageHeaderSolutions from "../components/PageHeaderSolutions";
import rootcauseanalysis from "../assets/images/applications/rootcauseanalysis.png";

const RootCauseAnalysis = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            Root Cause Analysis
          </h3>
        </div>

        <div className="grid gap-6 grid-cols-3">

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">1</div>
              <div>What is RCA?</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Root Cause Analysis (RCA) refers to the process of identifying
              factors that cause failures in a process and the term “root cause”
              is the most primary reason for a failure.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">2</div>
              <div>Why RCA?</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Although the symptom and immediate cause might be easy and quick
              to solve, failing to detect and treat the root cause will very
              likely lead to the problem recurring. The challenge in RCA is
              distinguishing between a symptom or intermediate cause, and the
              true root cause of a problem.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">3</div>
              <div>The Fishbone Diagram</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              One of the most common methodologies of RCA in manufacturing is
              the “Fishbone” diagram. It is simple and effective, but how strong
              is it in dealing with the complexity of today’s manufacturing
              processes?
            </div>
            <div className="justify-center ">
              <img
                src={rootcauseanalysis}
                className="w-[50%] h-[70%] m-3 "
                alt=""
              />
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4</div>
              <div>Shortcomings of Traditional RCA</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              <p>
                Some production lines are so complex that being simultaneously
                aware of every component and sub-process is humanly impossible.
              </p>
              <p className="pt-2">
                Requires time and a variety of professionals to perform — in
                most cases, process, quality and maintenance engineers.
              </p>
              <p className="pt-2">
                It’s natural that even experts can be biased towards certain
                ideas.
              </p>
              <p className="pt-2">
                Currently, most RCA information isn’t shared across
                manufacturing sites, leaving factories/plants of the same
                company to repeat each other’s mistakes, leading to unplanned
                downtime that could have been prevented.
              </p>
            </div>
          </div>

          <div className="text-xl  border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">5</div>
              <div>AI Based RCA</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Thanks to significant advances in machine learning and Big Data
              analytics, root cause analysis can be performed using automated
              methods. These methods are unbiased and based purely upon historic
              and real-time data from the production floor.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">5.1</div>
              <div>
                AI Based RCA /{" "}
                <i className="text-gray-500">Mutual Information</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Mutual Information is a mathematical solution suited to RCA. In a
              manufacturing setting involving a high volume of data and
              parameters, this approach can be used to leverage complex
              statistical knowledge to search for patterns. Mutual information
              is an investigative tool that aims to describe the mutual
              dependency between two random variables. When aiming to identify
              causal relationships, mutual information helps by identifying
              which information can be learned about one variable through data
              about another.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">5.2</div>
              <div>
                AI Based RCA /{" "}
                <i className="text-gray-500">Anomaly Detection</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              To perform RCA using machine learning, we need to be able to
              detect that something is out of the ordinary, or in other words,
              that an anomaly is present. 
              
              The machine learning model is trained
              to analyze the equipment’s data output under regular “healthy”
              operating conditions. 
              
              An anomaly can take the form of any pattern
              of deviation in the amplitude, period, or synchronization phase of
              a signal when compared to normal behavior. 
              

            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">5.3</div>
              <div>
                AI Based RCA / <i className="text-gray-500">Pattern Analysis</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
                Pattern discovery is helping to uncover hidden relationships,
                trends, and regularities in data that can point to the root
                causes of issues. By identifying recurring patterns in complex
                data, organizations can better understand the underlying factors
                contributing to problems, allowing them to take more targeted
                corrective actions. It can reveal correlations between different
                variables or events that may contribute to the root cause of a
                problem. By identifying which factors are frequently associated
                with failures, analysts can focus their investigation on those
                variables, leading to more efficient problem-solving.
                {/* <p className="m-2">
                  Temporal pattern discovery helps find sequences of events that
                  typically precede or follow a failure. This is particularly
                  useful when multiple variables change over time. By
                  recognizing the order in which events occur before an issue
                  arises, root causes can be traced more accurately, especially
                  in time-dependent processes.
                </p>
                <p className="m-2">
                  Machine learning models trained on historical data can
                  recognize patterns that typically lead to system failures and
                  use them to predict future issues. When new data matches known
                  failure patterns, RCA teams can anticipate the root cause even
                  before full failure occurs, allowing for proactive action. For
                  example: In predictive maintenance, a pattern might emerge
                  showing that specific vibration patterns in machinery often
                  precede bearing failure. This allows maintenance teams to take
                  corrective actions before the failure happens.{" "}
                </p>
                <p className="m-2">
                  Failure Pattern Classification What It Does: Pattern discovery
                  techniques classify different types of failures based on their
                  characteristics, such as failure modes, frequency, and
                  associated conditions. Use in RCA: By categorizing different
                  types of failures into known patterns, root causes can be
                  identified faster based on historical cases. Example: In
                  aviation, recurring maintenance issues might fall into
                  categories such as "engine-related" or "hydraulic system
                  failures." By matching current problems to these
                  classifications, maintenance teams can efficiently identify
                  root causes.
                </p>
                <p className="m-2">
                  Spatial Patterns in RCA What It Does: Pattern discovery also
                  helps in identifying spatial patterns in geographic data,
                  physical systems, or equipment. Use in RCA: These patterns can
                  point to root causes that are tied to specific locations or
                  regions within a system. Example: In a data center,
                  overheating issues might consistently occur in servers located
                  in a specific physical area, indicating a localized cooling
                  issue or airflow problem.
                </p> */}
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default RootCauseAnalysis;
