import React from "react";
import { useTranslation } from "react-i18next";
import PageHeaderSolutions from "../components/PageHeaderSolutions";

const ProcessMining = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeaderSolutions></PageHeaderSolutions>
      <section className="mt-24 w-full h-full relative bg-center bg-cover p-5 border-2 border-blue-900 rounded-lg bg-white">
        <div className="items-center text-center">
          <h3 className="mt-2 text-blue-900 text-4xl font-semibold mb-2">
            Process Mining
          </h3>
        </div>

        <div className="grid gap-6 grid-cols-3">
          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">1</div>
              <div>What is Process Mining?</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Process mining is a data-driven technique used to analyze and
              visualize the actual workflows, processes, and performance within
              an organization by extracting insights from event logs generated
              by IT systems. It helps identify inefficiencies, deviations from
              standard procedures, bottlenecks, and opportunities for process
              improvement by mapping out the real process flows, rather than the
              intended or theoretical ones.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">2</div>
              <div>Why Process Mining?</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Process mining is important because it provides organizations with
              a clear, data-driven view of their actual processes, allowing them
              to identify inefficiencies, bottlenecks, and deviations from
              standard workflows. It helps improve process efficiency, optimize
              resources, reduce costs, and enhance compliance by revealing
              hidden process issues that traditional process analysis methods
              may overlook.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">3</div>
              <div>Shortcomings of Traditional Process Mining</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Limited to Event Logs: It relies heavily on structured event logs,
              limiting its ability to capture unstructured or semi-structured
              data from other sources. Static Analysis: Traditional process
              mining offers a snapshot of historical processes, lacking
              real-time analysis for dynamic or continuously changing
              environments. Complex Data Preparation: Extracting and cleaning
              event log data from different systems can be time-consuming and
              require significant effort. Lack of Predictive Capabilities: It
              focuses on past process performance without providing predictive
              insights for future outcomes or proactive decision-making.
              Scalability Issues: Analyzing large datasets or complex processes
              can be slow and resource-intensive, limiting scalability in big
              organizations. Limited Support for Human Interactions: Traditional
              tools may not account for human decision-making steps that aren’t
              logged in systems, leading to incomplete process models.
            </div>
          </div>

          <div className="text-xl  border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4</div>
              <div>AI Based Process Mining</div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              AI-based Process Mining leverages advanced machine learning
              algorithms and AI techniques to analyze large datasets from
              equipment sensors, predict failures with higher accuracy, and
              continuously improve prediction models over time, enabling more
              efficient and precise maintenance planning.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.1</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Data Collection</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Capturing event logs, transaction data, and unstructured data from
              multiple systems such as ERP, CRM, and IoT devices to gain a
              comprehensive view of the processes.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.2</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">
                  Data Preprocessing and Integration
                </i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Cleaning, standardizing, and merging data from diverse sources,
              including structured, semi-structured, and unstructured data, to
              prepare it for analysis.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.3</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Process Discovery</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Using AI algorithms to automatically map out real business
              processes from event logs, identifying how they operate in
              practice, including variations and deviations.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.4</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Pattern Recognition</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Machine learning models are used to detect patterns, trends, and
              common process pathways, revealing hidden inefficiencies,
              bottlenecks, and correlations between events.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.5</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Predictive Analytics</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              AI provides predictive insights, forecasting future process
              performance, potential bottlenecks, or delays based on historical
              data and current trends.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.6</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Anomaly Detection</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              AI algorithms identify anomalies or deviations from the standard
              process flow in real-time, enabling quicker responses to
              unexpected issues.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.7</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Process Optimization</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              AI-powered tools recommend optimal process improvements,
              automation opportunities, or suggest alternative workflows to
              increase efficiency.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.8</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Continuous Monitoring</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              Real-time monitoring of processes, leveraging AI to provide
              ongoing analysis, adjust models dynamically, and offer predictive
              and prescriptive insights for process enhancement.
            </div>
          </div>

          <div className="text-xl border-2 border-gray-200 rounded-3xl shadow-2xl">
            <div className="pb-2 font-bold text-gray-900 flex justify-between m-1 opacity-90 ">
              <div className="pl-1 text-gray-400">4.9</div>
              <div>
                AI Based Process Mining /{" "}
                <i className="text-gray-500">Visualization and Reporting</i>
              </div>
              <div></div>
            </div>
            <div className="border-2 border-gray-100"></div>
            <div className="text-xl p-3 m-1 opacity-90 text-justify">
              User-friendly dashboards and visual tools provide process
              flowcharts, key metrics, and AI-driven insights, enabling users to
              interact with and understand the process data easily.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProcessMining;
